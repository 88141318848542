<template>
    <div>
        <app-table
            url="cards"
            :columns="columns"
            :actions="actions"
            :params="tableParams"
            ref="table"
        >
            <template v-slot:item.name="{ item }">
                <div class="d-flex align-center">
                    <div class="mr-3">{{ item.name }}</div>
                    <v-chip
                        v-if="item.is_default"
                        color="primary"
                        small
                        outlined
                    >Default</v-chip>
                    <v-chip
                        v-if="item.use_for_autopay"
                        color="orange"
                        outlined
                        small
                        class="tw-ml-2"
                    >Use for autopay</v-chip>
                </div>
            </template>
            <template v-slot:item.status="{ item }">
                <v-chip
                    :color="item.status === 'active' ? 'green' : 'red'"
                    small
                    outlined
                >{{ item.status }}</v-chip>
            </template>
            <template v-slot:actions>
                <v-btn small class="mr-3" @click="$refs.addModal.open()" color="primary">
                    <v-icon left dense>fal fa-plus</v-icon> Add
                </v-btn>
            </template>
        </app-table>
        <add-modal ref="addModal" @success="$refs.table.init()"/>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import Http from '@/utils/httpClient';
    import AppTable from '@/components/Table.vue';
    import AddModal from './modals/Add.vue'

    export default {
        components: {
            AppTable,
            AddModal,
        },
        data() {
            return {
                downloadingInvoice: false,
                downloadingReceipt: false,
                columns: [
                    {
                        text: 'Name',
                        value: 'name',
                        slotName: 'item.name',
                        sortable: true,
                        width: 200,
                    },
                    {
                        text: 'Expire',
                        value: 'expiry_date_for_human',
                        sortable: false,
                        width: 150,
                    },
                    {
                        text: 'Status',
                        value: 'status',
                        slotName: 'item.status',
                        sortable: false,
                        width: 150,
                    },
                ],
                tableParams: {
                    query: {
                        credit_card: [
                            'id',
                            'name',
                            'expiry_date_for_human',
                            'status',
                            'type',
                            'is_default',
                            'use_for_autopay',
                        ]
                    }
                },
                actions: [
                    {
                        label: "Don't use for autopay",
                        icon: 'fal fa-handshake-slash fa-fw',
                        isEligible: (item) => item.use_for_autopay,
                        handler: (item) => {
                            this.$root.$confirm({
                                title: 'Are you sure?',
                                message: `You are about to set the card "${item.name}" to be no longer use for autopay.`,
                                agreeButtonText: 'Yes, don\'t use it for autopay',
                                agreeButtonColor: 'primary',
                            }).then(() => {
                                this.$root.$loading.open({ text: 'Updating...' });
                                Http.patch(`credit-cards/${item.id}/dont-use-for-autopay`)
                                    .then(() => {
                                        this.$refs.table.init();
                                        this.$root.$loading.close();
                                        this.$root.$snackbar.open({
                                            text: 'Card was successfully updated.',
                                            type: 'success',
                                        })
                                    })
                                    .catch(() => {
                                        this.$root.$loading.close();
                                    })
                            });
                        }
                    },
                    {
                        label: 'Use for autopay',
                        icon: 'fal fa-handshake fa-fw',
                        isEligible: (item) => !item.use_for_autopay,
                        handler: (item) => {
                            this.$root.$confirm({
                                title: 'Are you sure?',
                                message: `You are about to set the card "${item.name}" to be use for autopay.`,
                                agreeButtonText: 'Yes, use it for autopay',
                                agreeButtonColor: 'primary',
                            }).then(() => {
                                this.$root.$loading.open({ text: 'Updating...' });
                                Http.patch(`credit-cards/${item.id}/use-for-autopay`)
                                    .then(() => {
                                        this.$refs.table.init();
                                        this.$root.$loading.close();
                                        this.$root.$snackbar.open({
                                            text: 'Card was successfully updated.',
                                            type: 'success',
                                        })
                                    })
                                    .catch(() => {
                                        this.$root.$loading.close();
                                    })
                            });
                        }
                    },
                    {
                        label: 'Set as default',
                        icon: 'fal fa-credit-card-front fa-fw',
                        isEligible: (item) => !item.is_default && item.status === 'active',
                        handler: (item) => {
                            this.$root.$confirm({
                                title: 'Are you sure?',
                                message: `You are about to set the card "${item.name}" as default.`,
                                agreeButtonText: 'Yes, set it as default',
                                agreeButtonColor: 'primary',
                            }).then(() => {
                                this.$root.$loading.open({ text: 'Updating...' });
                                Http.patch(`cards/${item.id}/default`)
                                    .then(() => {
                                        this.$refs.table.init();
                                        this.$root.$loading.close();
                                        this.$root.$snackbar.open({
                                            text: 'Default card was successfully updated.',
                                            type: 'success',
                                        })
                                    })
                                    .catch(() => {
                                        this.$root.$loading.close();
                                    })
                            });
                        }
                    },
                    {
                        label: 'Disable',
                        icon: 'fal fa-eye-slash fa-fw',
                        isEligible: (item) => item.status === 'active',
                        handler: (item) => {
                            this.$root.$confirm({
                                title: 'Are you sure?',
                                message: `You are about to disable "${item.name}".`,
                                agreeButtonText: 'Yes, disable it',
                                agreeButtonColor: 'error',
                            }).then(() => {
                                this.$root.$loading.open({ text: 'Disabling...' });
                                Http.patch(`cards/${item.id}/disable`)
                                    .then(() => {
                                        this.$refs.table.init();
                                        this.$root.$loading.close();
                                        this.$root.$snackbar.open({
                                            text: 'Card was successfully disabled.',
                                            type: 'success',
                                        })
                                    })
                                    .catch(({ response }) => {
                                        this.$root.$loading.close();
                                        this.$root.$snackbar.open({
                                            text: response.data.message,
                                            type: 'error',
                                            timeout: 0,
                                        });
                                    })
                            });
                        }
                    },
                    {
                        label: 'Enable',
                        icon: 'fal fa-eye fa-fw',
                        isEligible: (item) => item.status === 'disabled',
                        handler: (item) => {
                            this.$root.$confirm({
                                title: 'Are you sure?',
                                message: `You are about to enable "${item.name}".`,
                                agreeButtonText: 'Yes, enable it',
                                agreeButtonColor: 'error',
                            }).then(() => {
                                this.$root.$loading.open({ text: 'Enabling...' });
                                Http.patch(`cards/${item.id}/enable`)
                                    .then(() => {
                                        this.$refs.table.init();
                                        this.$root.$loading.close();
                                        this.$root.$snackbar.open({
                                            text: 'Card was successfully enabled.',
                                            type: 'success',
                                        })
                                    })
                                    .catch(({ response }) => {
                                        this.$root.$loading.close();
                                        this.$root.$snackbar.open({
                                            text: response.data.message,
                                            type: 'error',
                                            timeout: 0,
                                        });
                                    })
                            });
                        }
                    },
                    {
                        label: 'Delete',
                        icon: 'fal fa-trash-alt fa-fw',
                        isEligible: (item) => item.status !== 'deleted',
                        handler: (item) => {
                            this.$root.$confirm({
                                title: 'Are you sure?',
                                message: `You are about to delete "${item.name}" permanently.`,
                                agreeButtonText: 'Yes, delete it',
                                agreeButtonColor: 'error',
                            }).then(() => {
                                this.$root.$loading.open({ text: 'Deleting permanently...' });
                                Http.delete(`cards/${item.id}`)
                                    .then(() => {
                                        this.$root.$loading.close();
                                        this.$root.$snackbar.open({
                                            text: 'Card was successfully deleted.',
                                            type: 'success'
                                        })
                                        this.$refs.table.init();
                                    })
                                    .catch(() => {
                                        this.$root.$loading.close();
                                    })
                            });
                        }
                    },
                    {
                        label: 'Restore',
                        icon: 'fal fa-trash-undo-alt fa-fw',
                        isEligible: (item) => item.status === 'deleted',
                        handler: (item) => {
                            this.$root.$confirm({
                                title: 'Are you sure?',
                                message: `You are about to set the status of "${item.name}" as active.`,
                                agreeButtonText: 'Yes, set as active',
                                agreeButtonColor: 'success',
                            }).then(() => {
                                this.$root.$loading.open({ text: 'Setting as active...' });
                                Http.patch(`cards/${item.id}/restore`)
                                    .then(() => {
                                        this.$root.$loading.close();
                                        this.$root.$snackbar.open({
                                            text: 'Card was successfully set as active.',
                                            type: 'success'
                                        })
                                        this.$refs.table.init();
                                    })
                                    .catch(() => {
                                        this.$root.$loading.close();
                                    })
                            });
                        }
                    },
                ],
            }
        },
        computed: {
            ...mapGetters(['me'])
        },
        methods: {

        }
    }
</script>
